var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fileUsage",
    class: _vm.setClass
  }, [_c('div', {
    staticClass: "fileUsage__visual"
  }, [_c('em', {
    style: {
      width: _vm.getFileUsage * 2 + '%'
    }
  })]), _c('p', {
    staticClass: "fileUsage__remain"
  }, [_vm._v("남은공간 : " + _vm._s(_vm.getFileRemain + "MB"))]), _c('p', {
    staticClass: "fileUsage__text"
  }, [_vm._v(_vm._s(_vm.getFileUsage + "MB") + " "), _c('span', [_vm._v("/ 50MB")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }