<template>
  <div class="fileUsage" :class="setClass">
    <div class="fileUsage__visual">
      <em :style="{width: getFileUsage * 2 + '%'}"></em>
    </div>
    <p class="fileUsage__remain">남은공간 : {{ getFileRemain + "MB" }}</p>
    <p class="fileUsage__text">{{ getFileUsage + "MB" }} <span>/ 50MB</span></p>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String
    },
    fileUsage: {
      type: Number
    }
  },
  data() {
    return {};
  },
  computed: {
    // class binding
    setClass() {
      return {
        "fileUsage--green": this.color === "green"
      };
    },
    // 파일 사이즈 (B -> MB)
    getFileSize() {
      return this.fileUsage / (1024 * 1024);
    },
    // 파일 사용량
    getFileUsage() {
      return this.getFileSize.toFixed(2);
    },
    // 남은 사용량
    getFileRemain() {
      return (50 - this.getFileSize).toFixed(2);
    }
  }
};
</script>

<style lang="scss">
.fileUsage {
  width: 100%;
  height: 30px;
  overflow: hidden;

  &__visual {
    overflow: hidden;
    position: relative;
    height: 8px;
    background-color: #dcdcdc;
    border-radius: 8px;

    em {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: #54c7a2;
      border-radius: 8px;
    }
  }
  p {
    margin-top: 6px;
    color: #484948;
    font-size: 12px;
  }
  &__remain {
    float: left;
  }
  &__text {
    float: right;

    span {
      color: #309574;
    }
  }
}
.fileUsage--green {
  width: auto;
  height: 46px;
  padding: 0 16px;
  background-color: #54c7a2;

  .fileUsage__visual {
    background-color: #8be1c5;

    em {
      background-color: #309574;
    }
  }
  p {
    color: #ffffff;
  }
  .fileUsage__text {
    span {
      color: #026444;
    }
  }
}
</style>
